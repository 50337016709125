<template>
    <div>
            <NavBar>
                <img src="~assets/image/home/left.png" alt="" slot="left" class="left">
                <span slot="center">{{title}}</span>
            </NavBar>
            <div class="StaffDetail">
                <StaffDetail  :dataArr="dataArr" :nowScore="nowScore" :maxScore="maxScore" :weight="weight" :isShow="isShow"/>
            </div>
         
        
    </div>
</template>
<script>
import NavBar from 'components/navBar/NavBar.vue'
import StaffDetail from 'components/staffDetail/StaffDetail.vue'
import {getQuestion} from '../../network/normal'

    export default{
        name:'CheckDetail',
        components:{
            StaffDetail,
            NavBar
        },
        data(){
            return{
                  dataArr: [],
                  title:'',
                  maxScore:'',
                  weight:'',
                  isShow:false,
                  nowScore:''
            }
        },
        created(){
            let taskID = localStorage.getItem('taskId');
            let id=  this.$route.params.id;
            this.title = this.$route.params.title
            this.maxScore = this.$route.params.maxScore
            this.weight = this.$route.params.weight
            
                getQuestion(taskID,id).then((res)=>{
                    if(res.success){
                        this.dataArr = res.data;
                        this.isShow = res.extra.btnFlag
                        this.nowScore = res.extra.finalScore
                    }
                });
            
            
        }
    }
</script>
<style scoped>
.left{
        margin-left: 24px;
        width: 23px;
        height: 41px; 
    }
    .StaffDetail{
        margin-top: 100px;
    }
</style>

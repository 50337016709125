<template>
    <div>
       
        <!-- <div class="gradeDiv">
          <span class="title">满分:<span class="value">{{question.maxScore}}</span></span>
          <span class="title">评分:<input type="number" v-model="question.score" :name="'_contentId_'+question.id" step="0.1"/></span>
        </div> -->
        <div class="rateDiv">
          <span class="title marginR">综合评分:</span>
          <van-rate v-model="question.score" color="#239AF9" @change="onChange" size="28" gutter='8' class="marginR"/>
          <input  v-show="false" :name="'_contentId_'+question.id" v-model="question.score">
          <span>{{rateLabel}}</span>
        </div>
        <div class="standard">
          <p class="standard_title">评分要求</p>
          <p class="standard_detail">
            {{question.assessmentContent}}
          </p>
       
        </div>
   
      
    </div>
</template>
<script>
import { Rate } from 'vant';
import Vue from 'vue';

    export default{
        name:'StaffDetailItemQues',
        props:{
            question:{
                type:Object
            }
        },
        created(){
          Vue.use(Rate)
        },
        computed:{
            rateLabel(){
                let label = '';
                if(this.question.score == 1){
                  label = '极差'
                }
                if(this.question.score == 2){
                  label = '差'
                }
                if(this.question.score == 3){
                  label = '一般'
                }
                if(this.question.score == 4){
                  label = '好'
                }
                if(this.question.score == 5){
                  label = '极好'
                }
             return label;
            }
        },
      watch:{
        'question.score':{
          handler(newVal,oldVel){
            let score = Number(newVal);
              if(score < 0 || score > this.question.maxScore){
                  this.$toast.fail('填写的分数不合规,请重新填写');
                  this.question.score = oldVel
              }
          }
        }
      },
      methods:{
        onChange(value){
          this.question.score = value
        }
      }
      
    }
</script>
<style scoped>
.gradeDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 74px;
  padding-right: 94px;
  margin-top: 30px;
}
.rateDiv{
  display: flex;
  align-items: center;
  padding-left: 70px;
  padding-right: 70px;
  margin-top: 30px;
}
.marginR{
  margin-right: 20px;
}
.title {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
  line-height: 90px;
}
.title img {
  width: 29px;
  margin-right: 10px;
}
.value {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
  margin-left: 13px;
}

.gradeDiv input {
  margin-left: 10px;
  outline: none;
  border: #cccccc 1px solid;
  width: 140px;
}
.edit {
  color: #139ffc;
}
.standard {
  padding-left: 52px;
  padding-right: 26px;
}
.standard_title {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
  line-height: 80px;
}
.standard_detail {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
  line-height: 40px;
}
.saveDiv {
  border-bottom: solid 1px #eeeeee;
}
.save {
  width: 400px;
  height: 70px;
  background: linear-gradient(-90deg, #2998f8, #08a3fe);
  border-radius: 35px;
  color: #ffffff;
  text-align: center;
  line-height: 70px;
  border: none;
  display: block;
  margin: 53px auto;
}

</style>

<template>
  <div>
      <div class="detailDiv" @click="toshow">
        <div class="leftDiv">
          <div></div>
          <p>{{staffItem.label}}</p>
        </div>
        <img src="~assets/image/staffMessage/turnLeft.png" alt="" ref="trunLeft" :class="isShow?'turnBottom':''"/>
      </div>
     <staffDetailItemQues v-show="isShow"  v-for="item of (staffItem.questions)" :key="item.id" :question='item'></staffDetailItemQues>
  </div>
</template>
<script>
import StaffDetailItemQues from 'components/staffDetail/StaffDetailItemQues.vue'
export default {
  name: "StaffDetail",
  data() {
    return {
        isShow:false,
    }
  },
  components:{
    StaffDetailItemQues
  },
  props:{
      staffItem:{
          type:Object,
      }
  },
  methods:{
      toshow(){
          
          this.isShow = !this.isShow;
      }
  }

};
</script>
<style scoped>
.detailDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #eeeeee;
  padding-left: 24px;
  padding-right: 24px;
}
.detailDiv img {
  width: 60px;
}
.turnBottom{
    transform: rotate(90deg);
}
.leftDiv div {
  width: 8px;
  height: 30px;
  background: linear-gradient(-55deg, #2998f8, #08a3fe);
  border-radius: 4px;
  display: inline-block;
}
.leftDiv p {
  margin-left: 20px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
  line-height: 109px;
}
.leftDiv {
  display: flex;
  align-items: center;
}
</style>

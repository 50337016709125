<template>
  <div>
    <div >
      <img src="~assets/image/staffMessage/score.png" alt="" class="gradeImg" />
      <span class="gradeTitle">评分情况</span>
      <div class="gradeDiv">
        <span class="title">总分值:<span class="value">{{maxScore}}</span></span>
        <span class="title">权重:<span class="value">{{weight}}%</span></span>
        <span class="title">总评分:<span class="value">{{nowScore}}</span></span>
      </div>
    </div>
    <div class="lineDiv"></div>
    <form @submit.prevent="submit" ref="baseForm" >
      <StaffDetailItem  v-for="(item,index) of dataArr" :key="index" :staffItem="item" />
      <button class="submit" type="submit" v-if="isShow">提交</button>
    </form>

    
    
  </div>
</template>
<script>
import StaffDetailItem from 'components/staffDetail/StaffDetailItem.vue'
 import {saveAnswer} from '../../network/normal'
export default {
  name: "StaffDetail",
  components:{
    StaffDetailItem
  },
  props:{
    dataArr:{
      type:Array
    },
    maxScore:[Number,String],
    weight:[Number,String],
    isShow:Boolean,
    nowScore:[Number,String]
  },
  data() {
    return {
        
    }
  },
  methods: {
    submit(e){
      let taskID = localStorage.getItem('taskId');
      let request = {};
       const formData = new FormData(e.target);
      for (let [key, value] of formData) {
             request[key] = value
             if(value == ''){
                this.$toast.fail('请填写完整再提交');
                return 
             }
      }
      request['taskId'] = taskID 
       this.$toast.loading({
                    message: '努力加载中...',
                    forbidClick: false,
                    duration:0
        });
      saveAnswer(request).then((res)=>{
        this.$toast.clear();
          if(res.success){
            this.$toast.success(res.msg);
            this.$router.go(-1)
          }else{
            this.$toast.fail(res.msg);
          }
      });
    }

  },
};
</script>
<style scoped>
.gradeImg {
  width: 33px;
  margin-top: 29px;
  margin-left: 24px;
}
.gradeTitle {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
  margin-left: 18px;
}
.gradeDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 74px;
  padding-right: 94px;
}
.title {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
  line-height: 90px;
}
.title img {
  width: 29px;
  margin-right: 10px;
}
.value {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
  margin-left: 13px;
}
.lineDiv {
  width: 100%;
  height: 20px;
  background: #eeeeee;
}
.submit{
  width: 400px;
  background: linear-gradient(-90deg, #2998F8, #08A3FE);
  border-radius: 35px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 70px;
  border: none;
  margin: 80px auto;
  display: block;
}
.suggest{
  display: block;
  margin: 30px auto;
  width: 90%;
  height: 300px;
}
</style>
